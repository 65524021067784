// TODO: Should these be broken out into their own subsets? some seem reusable outside of theme
export const ThemeProps = {
  BACKGROUND: 'background',
  HEADER: 'header',
  CONTENT: 'content',
  NAV_TXT: 'nav-txt',
  LINK_TXT: 'link-txt',
  LINK_TXT_HOVER: 'link-txt-hover',
  BTN_BG: 'btn-bg',
  BTN_BG_HOVER: 'btn-bg-hover',
  BTN_BG_ACTIVE: 'btn-bg-active',
  BTN_BG_INACTIVE: 'btn-bg-inactive',
  BTN_TXT: 'btn_txt',
  BTN_TXT_HOVER: 'btn-txt-hover',
  BTN_TXT_ACTIVE: 'btn-txt-active',
  BTN_TXT_INACTIVE: 'btn-txt-inactive',
  ELEMENT: 'element',
  ELEMENT_TXT: 'element-txt',
  BORDER: 'border',
  ICON: 'icon',
  ICON_HOVER: 'icon-hover',

  ICON_2: 'icon-2',
  ICON_HOVER_2: 'icon-hover-2',
  HEADER_BG: 'header-bg',
  HEADER_TXT: 'header-txt',
  HEADER_ICON: 'header-icon,',
  HEADER_BG_2: 'header-bg-2',
  HEADER_TXT_2: 'header-txt-2',
  HEADER_ICON_2: 'header-icon-2',
  ODD_EVEN_OFFSET: 'odd-even-offset',

  BLACK: 'black',
  WHITE: 'white',

  LIGHTEST: 'lightest',
  LIGHTER: 'lighter',
  LIGHT: 'light',
  GRAY: 'gray',
  GRAYER: 'grayer',
  GRAYEST: 'grayest',
  DARK: 'dark',
  DARKER: 'darker',
  DARKEST: 'darkest',

  BEGINNER: 'beginner',
  INTERMEDIATE: 'intermediate',
  EXPERT: 'expert',

  FACEBOOK: 'facebook',
  EVENTBRITE: 'eventbrite',

  ACTIVE: 'active',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',

  LINK: 'link',
  LINK_HOVER: 'link-hover',

  // Start custom color scheme props, try to share/make consistent throughout site

  // DOR
  COMPONENT_BORDER: 'component-border',

  // TV
  SIDE_NAV_ITEM_BG: 'side-nav-item-bg',
  SIDE_NAV_ITEM_TXT: 'side-nav-item-txt',
  SIDE_NAV_ITEM_ICON: 'side-nav-item-icon',
  SIDE_NAV_ITEM_BG_ACTIVE: 'side-nav-item-bg-active',
  SIDE_NAV_ITEM_TXT_ACTIVE: 'side-nav-item-txt-active',
  SIDE_NAV_ITEM_ICON_ACTIVE: 'side-nav-item-icon-active',
  TOP_NAV_ITEM_TXT: 'top-nav-item-txt',
  ALERT_MARQUEE_BG: 'alert-marquee-bg',
  ALERT_MARQUEE_ALERT_TXT: 'alert-marquee-alert-txt',
  ALERT_MARQUEE_TEMP_TIME_TXT: 'alert-marquee-temp-time-txt',
  ALERT_OVERLAY_BG: 'alert-overlay-bg',
  ALERT_OVERLAY_TXT: 'alert-overlay-txt',
  ALERT_OVERLAY_TEMP_TIME_TXT: 'alert-overlay-temp-time-txt',
  LIFTS_TXT: 'lifts-txt',
  LIFTS_ICON: 'lifts-icon',
  EVENT_1: 'event-1',
  EVENT_2: 'event-2',

  // ECOMMERCE
  BTN_BG_ALT: 'btn-bg-alt',
  BTN_TXT_ALT: 'btn-txt-alt',
  BTN_CHANGE: 'btn-change',
  DATE_SELECT_BG: 'date-select',
  LINK_SEPARATOR: 'link-separator',

  // DESKTOP NAVIGATION
  NAV_BG: 'nav-bg',
  NAV_ITEM_TXT_SOLID: 'nav-item-txt-solid',
  NAV_ITEM_TXT_TRANSLUCENT: 'nav-item-txt-translucent',
  NAV_ITEM_TXT_ACTIVE: 'nav-item-txt-hover',
  NAV_ITEM_BG_ACTIVE: 'nav-item-bg-hover',
  NAV_ITEM_ACTIVE_ACCENT: 'nav-item-active-accent',
  LOGO_BG: 'logo-bg',
  LOGO_FOCUS_COLOR: 'logo-focus-color',

  // MOBILE NAVIGATION
  MAIN_NAV_TXT: 'main-nav-txt',
  MAIN_NAV_BG: 'main-nav-bg',
  MAIN_NAV_BORDER: 'main-nav-border',
  SUB_NAV_TXT: 'sub-nav-txt',
  SUB_NAV_BG: 'sub-nav-bg',
  SUB_NAV_BORDER: 'sub-nav-border',
  MENU_BTN_ICON: 'menu-btn-icon',
  MENU_BTN_BG: 'menu-btn-bg',
  CLOSE_BTN_ICON: 'close-btn-icon',
  CLOSE_BTN_BG: 'close-btn-bg',
  PROMO_BTN_TXT: 'promo-btn-txt',
  PROMO_BTN_BG: 'promo-btn-bg',
  DRAWER_BTN_TXT: 'alt-button-txt',
  DRAWER_BTN_BG: 'alt-btn-bg',
  WW_ICON_COLOR: 'ww-icon-color',
  NAV_PROMO_ITEM_TEXT: 'nav-promo-item-text',

  // CONTENT BLOCK HERO
  DIRECTIONAL_NAV: 'directional-nav',
  DIRECTIONAL_NAV_HOVER: 'directional-nav-active',
  PAGINATION: 'pagination',
  PAGINATION_HOVER: 'pagination-hover',
  PAGINATION_ACTIVE: 'pagination-active',

  // PROMOTIONS
  PROMO_ITEM_BG: 'promo-text-bg',
  PROMO_ITEM_HEADER: 'promo-text-header',
  PROMO_ITEM_BTN_BG: 'promo-text-btn-bg',
  PROMO_ITEM_BTN_TXT: 'promo-text-btn-txt',
  PROMO_ITEM_BTN_BG_HOVER: 'promo-text-bg-hover',
  PROMO_ITEM_BTN_TXT_HOVER: 'promo-text-btn-txt-hover',

  // SEASON TOGGLE
  TOGGLE_INACTIVE: 'toggle-inactive',
  TOGGLE_ACTIVE: 'toggle-active',

  // SIDEBAR ALERT
  ALERT_WARNING_BG: 'alert-warning-bg',
  ALERT_WARNING_CONTENT: 'alert-warning-content',
  ALERT_CRITICAL_BG: 'alert-critical-bg',
  ALERT_CRITICAL_CONTENT: 'alert-critical-content',

  // NAV TOGGLE
  NAV_TOGGLE_ICON: 'nav-toggle-icon',
  NAV_TOGGLE_ICON_SCROLLED: 'nav-toggle-icon-scrolled',
  NAV_TOGGLE_TXT: 'nav-toggle-txt',
  NAV_TOGGLE_TXT_SCROLLED: 'nav-toggle-txt-scrolled',
};

export const ColorProfiles = {
  BASE: 'base',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
};

export const StaticProfiles = {
  COLORS: 'colors',
  SHADES: 'shades',
  DIFFICULTY: 'difficulty',
  SOCIAL: 'social',
  STATUS: 'status',
  ALERTS: 'alerts',
  TV: 'tv',
};

export const FontFamily = {
  BASE_FONT: 'base-font',
  PRIMARY_FONT: 'primary-font',
  SECONDARY_FONT: 'secondary-font',
  TERTIARY_FONT: 'tertiary-font',
};

export const FontSize = {
  REG5: '8px',
  REG10: '10px',
  REG20: '12px',
  REG30: '14px',
  REG40: '16px',
  REG50: '18px',
  MED10: '20px',
  MED20: '22px',
  MED30: '24px',
  MED40: '26px',
  MED50: '28px',
  LRG10: '30px',
  LRG12: '32px',
  LRG20: '35px',
  LRG30: '40px',
  LRG40: '45px',
  LRG50: '50px',
};

export const TextTransformOptions = {
  NO_TRANSFORM: 'unset',
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase',
  CAPITALIZE: 'capitalize',
};

export const NavigationPromoStyleOptions = {
  CIRCULAR: 'circular',
  SQUARE: 'square',
};

export const GlobalLayoutValues = {
  MOBILE: {
    WEATHER_WIDGET_WIDTH: 140,
    HEADER_HEIGHT: {
      ACTIVE: 80,
      INACTIVE: 80,
    },
    SIDEBAR: {
      HEIGHT: 115,
    },
    STICKY_NAV: {
      HEIGHT: 70,
    },
    GRID: {
      MOBILE_ROW_REDUCTION: {
        THRESHOLD: 100, // what px value must row be >= to reduce
        REDUCTION_PCT: 0.4, // percentage to reduce row value
      },
    },
  },
  DESKTOP: {
    SEASON_TAB_HEIGHT: 45,
    SEASON_TAB_GAP: 30,
    WRAPPER_MAX: 1440,
    HEADER_HEIGHT: {
      ACTIVE: 125,
      INACTIVE: 75,
    },
    SIDEBAR: {
      PADDING: 25,
    },
  },
  ALL_PLATFORM: {
    WRAPPER: {
      ANIMATION_DURATION: 300, // ms
    },
  },
};
